<template>
  <v-card>
    <v-img height="200px" :src="temple.image ? temple.image : '/images/temples/default.png'">
      <v-card-title>
        <v-spacer/>
        <v-menu offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon class="me-n3 mt-n1" v-bind="attrs" v-on="on"><v-icon>{{ icons.mdiDotsVertical }}</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item :to="{name: 'edit-temple', params: {id: temple.id}}">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteForm = true" class="error">
              <v-list-item-title class="white--text">Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
    </v-img>
    <v-card-text class="text--primary mt-3">
      <p class="font-weight-semibold text-sm mb-1">{{ temple.state }}</p>
      <div class="d-flex align-end flex-wrap">
        <span class="font-weight-semibold text-2xl me-1 mb-2">{{ templeDisplayName(temple) }}</span>
      </div>
      <p class="text-xs text--secondary mb-0">135K Donors</p>
    </v-card-text>
    <v-dialog v-model="deleteForm" persistent max-width="400">
      <v-card>
        <v-card-title class="d-block w-full text-center">Confirm</v-card-title>
        <v-card-text class="text-center">Are you sure you want to delete this record?</v-card-text>
        <v-card-actions class="mt-2 text-center d-block">
          <v-btn color="secondary" outlined @click="deleteForm = false">No</v-btn>
          <v-btn color="primary" @click="deleteTemple">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'

export default {
  data() {
    return {
      deleteForm: false,
      loading: false,
      icons: {
        mdiDotsVertical,
      },
    };
  },
  props: {
    temple: {type: Object},
  },
  methods: {
    deleteTemple() {

      this.loading = true;
      axiosBackend.delete('/temples/' + this.temple.id)
        .then(() => {

          this.$emit('deleted');
          this.loading = false;
        });
    },
    templeDisplayName(temple) {
      return temple.name ? temple.name : (temple.village_city ? temple.village_city : (temple.district ? temple.district : (temple.state ? temple.state : temple.country)));
    }
  }
}
</script>
