<template>
  <v-row>
    <v-col cols="12" sm="12" class="text-right">
      <v-btn :to="{name: 'add-temple'}" color="primary" outlined icon>
        <v-icon v-html="icons.mdiPlus"/>
      </v-btn>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field
        rounded
        dense
        outlined
        clearable
        label="Search"
        :prepend-inner-icon="icons.mdiMagnify"
        class="app-bar-search flex-grow-0"
        :value="selectedFilters.search"
        @input="debounceSearch"
      />
    </v-col>
    <v-col v-for="(temple, key) in temples" :key="key" cols="12" sm="6" md="4" lg="3" xl="2">
      <statistics-card-vertical :temple="temple" @deleted="fetchTemples(queryParams)" />
    </v-col>
  </v-row>
</template>

<script>

  import {mdiArch, mdiMagnify, mdiPlus} from '@mdi/js'
  import _ from 'lodash';
  import StatisticsCardVertical from '@/components/temple/StatisticsCardVertical'

  export default {
    data() {
      return {
        temples: [],
        queryParams: {
          search: null,
        },
        icons: {
          mdiMagnify,
          mdiArch,
          mdiPlus,
        },
        loading: true,
      };
    },
    computed: {
      selectedFilters() {

        return  {
          search: this.queryParams.search,
        };
      }
    },
    components: {
      StatisticsCardVertical,
    },
    watch: {
      queryParams: {
        deep: true,
        async handler() {

          if (this.$router.currentRoute.fullPath !== this.$router.resolve({name: this.$router.currentRoute.name, query: this.queryParams}).route.fullPath) this.$router.replace({query: this.queryParams});
          await this.fetchTemples(this.queryParams);
        }
      }
    },
    async created() {

      if (Object.entries(this.$router.currentRoute.query).length > 0) {

        this.queryParams = {
          search: this.$router.currentRoute.query.search && this.$router.currentRoute.query.search !== 'undefined' ? this.$router.currentRoute.query.search : null,
        };
      } else {

        this.fetchTemples(this.queryParams);
      }
    },
    methods: {
      fetchTemples(params) {
        this.loading = true;
        axiosBackend.get('/temples', {params})
          .then((response) => {

            this.temples = response.data.temples;
            this.loading = false;
          });
      },
      debounceSearch: _.debounce(function (value) {

        this.updateFilter(value, 'search')
      }, 500),
      updateFilter(value, field) {

        this.queryParams[field] = value;
      },
    },
  }
</script>
